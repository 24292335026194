function init(){
  console.log('hello')
  const navigationTrigger = document.querySelector('.navigation-trigger')
  const navigationContainer = document.querySelector('.navigation')
  navigationTrigger.addEventListener('click', () => {
    if( navigationContainer.classList.contains('visible')) {
      navigationContainer.classList.remove('visible')
    } else {
      navigationContainer.classList.add('visible')
    }
  })
}

init()
